var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "pages.customer.CustomerDeleteAccount.header",
                expression: "'pages.customer.CustomerDeleteAccount.header'"
              }
            ]
          }),
          _c("CCardBody", [
            _c("div", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "pages.customer.CustomerDeleteAccount.text",
                  expression: "'pages.customer.CustomerDeleteAccount.text'"
                }
              ]
            }),
            _c("ul", [
              _c("li", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "pages.customer.CustomerDeleteAccount.listItem1",
                    expression:
                      "'pages.customer.CustomerDeleteAccount.listItem1'"
                  }
                ]
              }),
              _c("li", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "pages.customer.CustomerDeleteAccount.listItem2",
                    expression:
                      "'pages.customer.CustomerDeleteAccount.listItem2'"
                  }
                ]
              }),
              _c("li", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "pages.customer.CustomerDeleteAccount.listItem3",
                    expression:
                      "'pages.customer.CustomerDeleteAccount.listItem3'"
                  }
                ]
              }),
              _c("li", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: "pages.customer.CustomerDeleteAccount.listItem4",
                    expression:
                      "'pages.customer.CustomerDeleteAccount.listItem4'"
                  }
                ]
              })
            ])
          ]),
          _c(
            "CCardFooter",
            [
              _c(
                "CButton",
                {
                  directives: [
                    {
                      name: "t",
                      rawName: "v-t",
                      value: "pages.customer.CustomerDeleteAccount.button",
                      expression:
                        "'pages.customer.CustomerDeleteAccount.button'"
                    }
                  ],
                  attrs: { color: "danger" },
                  on: { click: _vm.deleteAccount }
                },
                [_vm._v(" Schließen ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }